import React from "react";

import {
  HeaderContainer,
  HeaderWrapper,
  HeaderTitle,
  HeaderSubtitle,
  GithubLogin,
} from "./";
import { config } from "../../config";

export const Header = () => {
  const renderTitle = () => {
    return (
      <a href="https://mitangs.tech" className="header-link">
        {config.title}
      </a>
    );
  };

  return (
    <HeaderContainer>
      <GithubLogin isAbsolute={true} />
      <HeaderWrapper>
        <HeaderTitle>{renderTitle()}</HeaderTitle>
        <HeaderSubtitle>{config.subtitle}</HeaderSubtitle>
      </HeaderWrapper>
    </HeaderContainer>
  );
};
