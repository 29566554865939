export const config = {
  // Your Github Converted Token To Know How To Get Your Token Look at Readme.md
  githubConvertedToken: "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",

  // Your Github UserName
  githubUserName: "mitang321",

  // Your Github Repo Name Where You Have your issues as Blog
  githubRepo: "products",

  // Set it to true if you have a Github app to add to this project
  // and fill the client ID & secret
  enableOAuth: false,
  OAuthClientID: "Iv1.9f40fd53257d4102",
  OAuthSecret: "cc3db76ffe9848c92d83172807eececa79f0eb22",

  // Your Personal Blog Title
  title: "Mitang Hindocha",

  // Your Personal Blog Subtitle
  subtitle: "PRODUCT LIST",

  // Header customization
  header: {
    backgroundColor: "#f1f6f8", // can be a CSS gradient
    backgroundColorDark: "#28313B",
    titleColor: "#ff5252",
    titleColorDark: "#16a085",
    subtitleColor: "#37474f",
    subtitleColorDark: "#D8DEE9",
  },
};
