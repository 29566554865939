import React, { useState, useEffect } from "react";
import { gql, ApolloClient, InMemoryCache } from "@apollo/client";
import { Header } from "../Components/Header";
import { Loader } from "../Components/Common";
import { BlogContainer } from "../Components/Blog";
import { Card } from "../Components/Blog/Card";
import { config } from "../config";

const client = new ApolloClient({
  uri: "https://api.github.com/graphql",
  headers: {
    Authorization: `Bearer ${config.githubConvertedToken}`,
  },
  cache: new InMemoryCache(),
});

const GET_POSTS = gql`
  {
    repository(owner: "${config.githubUserName}", name: "${config.githubRepo}") {

    issues(
        first: 100
        states: OPEN
        filterBy: { labels: "blog" }
        orderBy: { direction: DESC, field: CREATED_AT }
      ) {
        nodes {
          title
          body
          bodyHTML
          bodyText
          number
          labels(first: 100) {
            nodes {
              color
              name
              id
            }
          }
          author {
            url
            avatarUrl
            login
          }
          updatedAt
          id
        }
      }
    }
  }
`;

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const { data } = await client.query({
          query: GET_POSTS,
        });

        setPosts(data?.repository?.issues?.nodes);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("An error occurred while fetching data.");
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  return (
    <>
      <Header />
      <BlogContainer>
        {loading ? (
          <Loader />
        ) : error ? (
          <p>{error}</p>
        ) : (
          posts.map((post) => <Card blog={post} key={post.number} />)
        )}
      </BlogContainer>
    </>
  );
};

export default Blog;
