import styled from "styled-components";

export const CardContainer = styled.div`
  cursor: pointer;
  margin-bottom: 6rem;
  border-radius: 10px;
  padding: 10px;
  transition: 0.2s;
  position: relative;
  }

  background-color: ${(props) =>
    props.theme.mode === "light" ? "#fbeee6" : "#2E3440"};

  :hover {
    background-color: ${(props) =>
      props.theme.mode === "light" ? "#FFFFFF" : "#485461"};
  }
`;
